import { request } from "@/request/http";

export default {
  searchColleague: (data) => {
    return request("GET", `/index.php/project/searchTogether`, data);
  },

  getCenterList: (data) => {
    return request("POST", `/index.php/adminCenter/getCenterList`, data);
  },

  delete: (data) => {
    return request("POST", `/index.php/adminCenter/delete`, data);
  },

  searchSchool:(data) => {
    return request("POST", `/index.php/adminCenter/searchSchool`, data);
  },

  update: (data) => {
    return request("POST", `/index.php/adminCenter/update`, data);
  },

  getDataInfo: (data) => {
    return request("POST", `/index.php/adminCenter/getDataInfo`, data);
  },
  

  

  
};