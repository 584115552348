import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import API from '@/api/manager/center/index.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "colleague",
        data()
        {
            return{
                colleagueList:[],
                colleagueListTotal:0,
                // 根据同事内容搜索
                searchContent:"",
                searchColleagueList:[],
                searchSchoolList:[],
                // 学校id 
                school_id:'',
                currentPage:1,
                pageSize:10,
                colleague_id:'',
                dialogVisible : false,
                centerName:'',
                data_id:'',
                title:'添加中心',
            }
        },
        /**
         * @name: 生命周期创建
         * @desc: 页面创建时获取数据
         * @author: camellia
         * @date: 2024-02-05
         */
        created()
        {
            let self=this;
            self.getData()
        },
        methods:
        {
            add()
            {
                this.dialogVisible = true;
                this.title = '添加中心';
                this.data_id = '';
            },
            getDataInfo(row)
            {
                let self = this;
                nprogressStart();
                self.$Loading.show();
                self.title = '修改中心';
                self.dialogVisible = true;
                self.data_id = row.id;
                self.centerName = '';
                self.school_id = '';
                self.colleague_id = '';
                let param = {
                    id: row.id
                }
                API.getDataInfo(param)
                .then((result)=>{
                    if(result.code > 0 )
                    {
                        self.centerName = result.data.name;
                        self.school_id = result.data.school_id;
                        self.colleague_id = result.data.admin_userid;
                        self.searchSchoolList = result.data.schoolList;
                        self.searchColleagueList = result.data.teacherList;
                    }
                    else
                    {
                        Message.error('系统错误')
                    }
                    nprogressClose();
                    self.$Loading.hide();
                })
            },
            searchSchool(e)
            {
                try 
                {
                    if(e)
                    {
                        let self = this;
                        let param = {
                            search: e
                        }
                        API.searchSchool(param)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.searchSchoolList = result.data
                            }
                            else
                            {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })
                    }
                } 
                catch (error) 
                {
                    Message.error('系统错误');
                }
            },
            getData()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        search : self.searchContent
                    };
                    nprogressStart();
                    self.$Loading.show();
                    // 获取同事列表请求
                    API.getCenterList(data)
                        .then((result)=>{
                            nprogressClose();
                            self.$Loading.hide();
                            if(result.code > 0 )
                            {
                                self.colleagueList = result.list
                                self.colleagueListTotal = result.list.length - 0 
                            }
                        })                
                } 
                catch (error) 
                {
                    // Message.error('系统错误') 
                }
            },
            deleteColleague(e)
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        id : e.id
                    };
                    // 删除请求
                    self.$confirm('确认要删除该中心吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        API.delete(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                        })  
                    }).catch(() => {
                        // Message.info('已取消');        
                    });
                } 
                catch (error) 
                {
                    // Message.error('系统错误');
                }
            },
            confirmUpdate()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        teacher_id : self.colleague_id,
                        school_id : self.school_id,
                        centerName : self.centerName,
                        id: self.data_id
                    };
                    // 添加同事请求
                    API.update(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.dialogVisible = false;
                                self.colleague_id = '';
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'success',
                                    displayTime:1500
                                })
                            }
                            else
                            {
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })                
                } 
                catch (error) 
                {
                    Message.error('系统错误') 
                }
            },
            searchColleague(e)
            {
                try 
                {
                    let self = this;
                    let data = {
                        name: e
                    }
                    if(e)
                    {
                        API.searchColleague(data)
                        .then((result)=>{
                            if(result.code > 0 )
                            {
                                self.searchColleagueList = result.data
                            }
                            else
                            {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message:result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType:'warning',
                                    displayTime:1500
                                })
                            }
                        })
                    }
                } 
                catch (error) 
                {
                    // Message.error('系统错误')
                }
            },

            handleSearchColleague()
            {
                let self = this;
                self.currentPage = 1;
                self.getData()
            },
        }       
};