<template>
    <div class="logList">
        <div class="logButton">
            <div class="export">
                <el-button type="primary" @click="add()">添加中心</el-button>
            </div>
            <div class="search">
                <!-- 搜索 -->
                <div class="searchContent">
                    <el-input placeholder="请输入中心名称！" v-model="searchContent" class="input-with-select"></el-input>
                </div>
                <el-button type="primary" @click="handleSearchColleague">搜索</el-button>
            </div>
        </div>
        <!-- 列表 -->
        <el-card>
            <el-table ref="multipleTable" :data="colleagueList.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
                tooltip-effect="dark" style="width: 100%">
                <!-- 序号 -->
                <el-table-column type="index" label="序号" width="100"></el-table-column>
                <el-table-column prop="name" label="中心名称"></el-table-column>
                <el-table-column prop="schoolName" label="学校名称"></el-table-column>
                <el-table-column prop="admin_name" label="管理员"></el-table-column>
                <!-- 操作-->
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!-- 修改 -->
                        <el-button type="primary" @click="getDataInfo(scope.row)">修改</el-button>
                        <!-- 假删除 -->
                        <el-button type="danger" @click="deleteColleague(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination :current-page.sync="currentPage" background :page-size="pageSize" layout="prev, pager, next"
                :total="colleagueListTotal"></el-pagination>
        </el-card>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
            <span class="dialog-span">
                <el-input v-model="centerName" placeholder="请输入中心名称！"></el-input>
                <br><br>
                <el-select v-model="colleague_id" multiple filterable remote clearable placeholder="请输入教师姓名，可多选！"
                    :remote-method="searchColleague">
                    <el-option v-for="item in searchColleagueList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
                <br><br>
                <el-select v-model="school_id" filterable remote clearable placeholder="请输入学校名称！"
                    :remote-method="searchSchool">
                    <el-option v-for="item in searchSchoolList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmUpdate">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// 引入试题列表js
import logList from '@/assets/js/manager/center/index.js';
export default {
    ...logList,
};
</script>

<style lang="scss" scoped>// 引入试题列表组件css
@import '@/assets/css/manager/center/index.scss';</style>
